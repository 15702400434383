@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


body {
  margin: 0;
  font-family: 'Nunito', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Nunito', sans-serif;

}
